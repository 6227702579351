import $store from '../store'
import * as authService from '../services/auth.service'
import Vue from 'vue'
import router from '@/router'
/**
 * Current user state initialization
 * @WARN Must be always first in middleware chain
 */
export function initCurrentUserStateMiddleware(to, from, next) {
  const currentUserId = $store.state.user.currentUser.id

  if (authService.getRefreshToken() && !currentUserId) {
    return authService
      .refreshTokens()
      .then(() => $store.dispatch('user/getCurrent'))
      .then(() => next())
      .catch((_) => {})
  }
  next()
}

/**
 * Check access permission to auth routes
 */
let checkExistUser = false
export function checkAccessMiddleware(to, from, next) {
  if (to.meta.isAuth) {
    Vue.$cookies.set('historyPath', to.fullPath)
  }
  const user = Vue.$cookies.get('user')
  if (!user && !checkExistUser && to.meta.isAuth) {
    checkExistUser = true
    Vue.$cookies.remove('accessToken')
    Vue.$cookies.remove('refreshToken')
    Vue.$cookies.remove('tokenExpired')
    Vue.$cookies.remove('rememberMe')
    Vue.$cookies.remove('user')
    next({ name: 'login' })
  }
  checkExistUser = false
  const currentUserId = user ? user.id : ''
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth)
  if (authService.getAccessToken() && (to.name === 'login' || to.name === 'organization')) {
    router.push('/')
  }
  if (isAuthRoute && currentUserId) return next()
  if (isAuthRoute) return next({ name: 'login' })
  next()
}

export function checkAccessIsAdmin(to, from, next) {
  const user = Vue.$cookies.get('user')

  const currentUserId = user ? user.id : ''
  const currentUserType = user ? user.user_type : ''
  const isAuthRoute = to.matched.some((item) => item.meta.isAdmin)

  if (isAuthRoute && currentUserId && currentUserType === 'employee') return next()
  if (isAuthRoute) return next({ name: 'login' })
  next()
}

export function checkAccessIsOrgAdmin(to, from, next) {
  const user = Vue.$cookies.get('user')

  const currentUserId = user ? user.id : ''
  const currentUserType = user ? user.user_type : ''
  const currentUserSubType = user ? user.user_sub_type : ''

  const isAuthRoute = to.matched.some((item) => item.meta.isOrgAdmin)

  if (isAuthRoute && currentUserId && currentUserType === 'employee') return next()
  if (
    isAuthRoute &&
    currentUserId &&
    currentUserType === 'customer' &&
    currentUserSubType !== 'author'
  )
    return next()
  if (isAuthRoute) return next({ name: 'login' })
  next()
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title)

  if (pageTitle) window.document.title = pageTitle.meta.title
  next()
}
export function checkTokenUniqKeyMiddleware(to, from, next) {
  const token = to.query.token
  const uniqkey = to.query.uniqkey
  $store
    .dispatch('player/checkTokenValidity', token)
    .then((response) => {
      Vue.$cookies.set('token', token)
      Vue.$cookies.set('uniqkey', uniqkey)
    })
    .catch(() => {
      Vue.$cookies.remove('token')
      Vue.$cookies.remove('uniqkey')
    })
  next()
}
export function checkPermissionAccess(to, from, next) {
  const user = Vue.$cookies.get('user')
  if (user) {
    const paths = [
      {
        permission: 'account',
        path: '/users'
      },
      {
        permission: 'campaigns',
        path: 'campaigns'
      },
      {
        permission: 'library',
        path: '/library'
      },
      {
        permission: 'loop',
        path: '/loop_assets'
      },
      {
        permission: 'player',
        path: '/players'
      }
    ]

    const menu = user.menu
    const path = paths.filter((x) => to.path.includes(x.path))[0]
    if (user.user_type === 'customer' && path) {
      if (!menu[path.permission]) return next({ name: 'overview' })
    }
  }
  next()
}
